import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "number-input"
    }}>{`Number input`}</h2>
    <ComponentCode name="Number input" component="number-input" variation="number-input" hasReactVersion="numberinput--default" hasLightVersion hasVueVersion="numberinput--default" hasAngularVersion="?path=/story/number--basic" codepen="zXqBMy" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "number-input-mobile"
    }}>{`Number input mobile`}</h2>
    <ComponentCode name="Number input mobile" component="number-input" variation="number-input--mobile" hasLightVersion codepen="gyrMqw" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="number-input" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      